import type { TypographyProps } from "@mui/material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { IconHelpCircleFilled } from "@tabler/icons-react";

import { useFormatCurrency } from "~/modules/currency";
import type { Money } from "~/types";

export function MoneyWithOriginal({
  money,
  variant = "sh5",
  ...others
}: { money: Money } & Omit<TypographyProps, "component">) {
  const formatCurrency = useFormatCurrency(money.currency);
  const formatFromCurrency = useFormatCurrency(money.from?.currency ?? money.currency);

  if (!money.from || money.from.currency === money.currency) {
    return (
      <Box component="span">
        <Typography {...others} component="span" variant={variant}>
          {formatCurrency(money.amount)}
        </Typography>
      </Box>
    );
  }

  return (
    <Tooltip title={`estimated conversion from ${formatFromCurrency(money.from?.amount)}`}>
      <Stack
        component="span" // Using "span" because "div" can't be used inside a "p" component
        spacing={0.5}
        direction="row"
        sx={{ alignItems: "center", display: "inline-flex" }}
      >
        <Box component="abbr" title="estimated" sx={{ color: "neutral.3", textDecoration: "none" }}>
          est.
        </Box>
        <Typography {...others} component="span" variant={variant}>
          {formatCurrency(money.amount)}
        </Typography>
        {/* hard-code color because this componentcould be used in previous theme without the new color system  */}
        <IconHelpCircleFilled size={15} style={{ color: "#afafaf" }} />
        {/* <IconHelpCircleFilled size={15} style={{ color: theme.palette.neutral[5] }} /> */}
      </Stack>
    </Tooltip>
  );
}
